$_private-sidebar-color: #0077c0;

.private-sidebar {
  background: $_private-sidebar-color;
  height: 100%;
  padding: 1rem 0;
  overflow: hidden;
  overflow-y: auto;

  padding-top: 0;

  small {
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.55);
    display: block;
    padding: 0.5rem 1rem;
  }

  ul {
    list-style: none;
    //margin: 0.75rem 0;
    padding: 0;
  }

  hr {
    margin: 1rem 0;
    border-top: 1px solid rgba(255, 255, 255, 0.15);
  }

  a {
    position: relative;
    display: block;
    padding: 0.5rem 1rem;
    color: rgba(255, 255, 255, 0.85);
    text-decoration: none;
    transition: color 0.5s;

    &:after {
      position: absolute;
      content: '';
      height: 100%;
      width: 3px;
      background-color: #fff;
      top: 0;
      left: 0;
      opacity: 0;
      transition: opacity 0.35s;
    }

    // &.active {
    //   color: #ffffff;
    //   background-color: rgba(255, 255, 255, 0.035);

    //   &:after {
    //     opacity: 1;
    //   }
    // }

    &:hover {
      color: #ffffff;

      &:after {
        opacity: 1;
      }
    }
  }

  a[class='active'] {
    color: #ffffff;
    background-color: rgba(255, 255, 255, 0.035);

    &:after {
      opacity: 1;
    }
  }

  &--logo {
    position: sticky;
    top: 0;
    left: 0;
    background: $_private-sidebar-color;
    z-index: 99;

    display: flex;
    justify-content: center;
    padding: 1rem 0;

    a {
      color: inherit;
      text-decoration: none;
      padding: 0;
      position: static;
      &:after {
        content: none;
      }
      &:hover {
        color: inherit;
      }
    }
  }
}

.private-sidebar-height {
  padding-bottom: 10rem;
}

@import '../../styles/variables';
@import '~bootstrap/scss/mixins';

//
// image background
.image-background {
  background: #cccccc no-repeat center center;
  width: 100%;
  height: 100%;
  background-size: cover;
}

//
// image-avatar
.image-avatar {
  border: 1px solid $gray-300;
}

//
// image-mark
.image-mark--sm {
  height: 72px;
}

.image-mark--md {
  height: 96px;
}

.image-mark--lg {
  height: 120px;
}

//
// image-profile
.image-profile {
}

.image-profile--rounded {
  border-radius: 50%;
}

.image-profile--border {
  border: 1px solid $gray-300;
}

.image-profile--sm {
  height: 32px;
  width: 32px;
}

.image-profile--md {
  width: 40px;
  height: 40px;
}

.image-profile--lg {
  width: 100px;
  height: 100px;
}

//
// Organisation Logo
.image-company-logo {
  display: flex;
  text-align: center;
  overflow: hidden;
  align-items: center;
  justify-items: center;
  background-color: $white;
  border: 1px solid $gray-300;

  img {
    width: 100%;
    height: auto;
    display: inline-block;
  }
}

.image-company-logo--bordered {
  padding: 4px;
  border: 1px solid $gray-300;
}

.image-company-logo--sm {
  width: 48px;
  height: 48px;
}

.image-company-logo--md {
  width: 100px;
  height: 100px;
}

.image-company-logo--lg {
  width: 140px;
  height: 140px;
}

//
// Main Logo (IAF CertSearch)
.image-logo {
  width: auto;
}

.image-logo--sm {
  height: 36px;
}

.image-logo--md {
  width: auto;
  height: 48px;
}

.image-logo--lg {
  //height: 92px;
  width: 128px;
  height: auto;
}

//
// QualityTrade Logo
.image-logo-qt {
  height: auto;
}

.image-logo-qt--sm {
  width: 120px;
}

.image-logo-qt--md {
  width: 198px;
}

.image-logo-qt--lg {
  width: 198px;
}

.image-logo-qt--block {
  display: block;
}

//
// IAF Logo
.image-logo-iaf {
  width: 150px;
  height: auto;
}

.private-sidebar {
  background: linear-gradient(#023b89 0%, #107ad9 100%);
  height: 100%;
  padding: 1rem 0;
  overflow: hidden;
  overflow-y: auto;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  hr {
    margin: 1rem 0;
    border-top: 1px solid rgba(255, 255, 255, 0.05);
  }

  a {
    position: relative;
    display: block;
    padding: 0.75rem 1rem;
    color: rgba(255, 255, 255, 0.35);
    text-decoration: none;
    transition: color 0.5s;

    &:after {
      position: absolute;
      content: '';
      height: 100%;
      width: 3px;
      background-color: #fff;
      top: 0;
      left: 0;
      opacity: 0;
      transition: opacity 0.35s;
    }

    // &.active {
    //   color: #ffffff;
    //   background-color: rgba(255, 255, 255, 0.035);

    //   &:after {
    //     opacity: 1;
    //   }
    // }

    &:hover {
      color: #ffffff;

      &:after {
        opacity: 1;
      }
    }
  }

  a[class='active'] {
    color: #ffffff;
    background-color: rgba(255, 255, 255, 0.035);

    &:after {
      opacity: 1;
    }
  }
}

@import '../../styles/variables';
@import '~bootstrap/scss/mixins';

.local-layout {
  background-color: #fff;
  width: 100%;
  min-height: 100vh;
}

////////////////////////////////////////////////////////////////////
// header
.local-layout__header {
  border-bottom: 1px solid $gray-300;
  position: fixed;
  width: 100%;
  top: 0;
  background: #fff;
  z-index: 10;
  height: 100px; // sync changes
}

////////////////////////////////////////////////////////////////////
// sidebar
%make-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;
  // min-height: min-content;
  padding: 0 $grid-gutter-width * 0.5;
}

//
.local-layout__sidebar {
  @extend %make-center;
  background: linear-gradient(#0077c0 0%, #0e4a94 100%);
  color: #fff;
}

.local-layout__sidebar__inner {
  max-width: 320px;
  margin: 0 auto;
}

// .local-layout__sidebar__title) {
//   // margin-bottom: 2rem;

//   // &:before {
//   //   content: '';
//   //   width: 60px;
//   //   height: 1px;
//   //   margin-bottom: 2rem;
//   //   display: block;
//   //   background-color: lighten($primary, 50%);
//   // }
// }

.local-layout__sidebar__text {
  margin-bottom: 2rem;
}

////////////////////////////////////////////////////////////////////
// content

.local-layout__content {
  flex-grow: 1;
  display: flex;
  align-items: center;
  background-color: #ffffff;
}

.local-layout__content--register {
  flex-grow: 1;
  display: flex;
  background-color: #ffffff;
  padding-top: 4em;
}

.local-layout__form {
  width: 100%;
  max-width: 450px;
  margin: 0 auto;
  padding: 3rem 0 3rem 0;
}

.local-layout__content__inner {
  margin: 0 auto;
  padding: 3rem 0 3rem 0;
}

.local-layout__content__inner--form {
  width: 100%;
  max-width: 550px;
}

.local-layout__form__title {
  text-align: center;
  color: $gray-800;
  font-size: 28px;
}

.local-layout__form__text {
  text-align: center;
  font-size: 18px;
  color: $gray-600;
  margin-bottom: 3rem;
}

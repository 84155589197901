.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 0 auto;
  flex-direction: column;
}

.page__loading__backdrop {
  top: 0;
  left: 0;
  z-index: 999;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.75);
}

@import '../../styles/variables';
@import '~bootstrap/scss/mixins';

$_sidebar-width: 240px;

.header {
  width: 100%;
  font-size: 14px;
  top: 0;
  left: 0;

  // as per mentioned in meeting
  height: 100px;

  &--private {
    width: calc(100vw - #{$_sidebar-width} - 1rem);
    left: $_sidebar-width;
  }

  .header-nav {
    max-width: 1400px;
    margin: 0 auto;
    height: 100%;
  }

  .header-nav a {
    font-size: 14px;
  }
}

@keyframes header-sticky-animation {
  from {
    top: -100px;
  }
  to {
    top: 0;
  }
}

.header--sticky {
  position: fixed;
  top: 0;
  z-index: 1000;
  animation-name: header-sticky-animation;
  animation-duration: 0.5s;
}

.header--fluid {
  .header-nav {
    max-width: 100%;
    margin: 0 auto;
  }
}

.header--sticky-filler {
  height: 96px;
}

.header--light {
  background-color: #fff;
  border-bottom: 1px solid rgb(232, 232, 232);
}

.header--fixed {
  position: fixed;
  top: 0;
  // left: 0;
  z-index: 100;
}
